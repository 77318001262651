$(function(){
    
});

var header = $('header');

if($('body').hasClass('HomePage')){
    $(window).on('scroll resize', function(){
        var scrollPos = $(document).scrollTop();
        if(scrollPos > 200){
            if(!header.hasClass('bg-primary')){
                header.removeClass('bg-transparent').addClass('bg-primary');
            }
        }else{
            header.removeClass('bg-primary').addClass('bg-transparent');
        }
    });
}

$('#offcanvasRight').on('show.bs.offcanvas', (e) => {
    $('.hamburger').addClass('is-active');
});

$('#offcanvasRight').on('hide.bs.offcanvas', (e) => {
    $('.hamburger').removeClass('is-active');
});