$(function(){
    $('.modal').appendTo("body") 
});

document.addEventListener("touchstart", function() {}, true);

var crawlerAgentRegex = /bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i;

if (!crawlerAgentRegex.test(navigator.userAgent)) {
    
}

Lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true,
    'albumLabel': "Bild %1 von %2",
    'disableScrolling': true
});