var cookieApprovalModal = $('#cookie-consent');

var noCookiesModal = $('#no-cookies');

var googleTagManagerID = $('body').data('gtmcode');

var crawlerAgentRegex = /bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i;

window.dataLayer = window.dataLayer || [];

var consentState;
var consentGroups;

var cookiePath = window.location.host.split('.').reverse();
cookiePath = '.' + cookiePath[1] + '.' + cookiePath[0];

cookiePath = cookiePath.indexOf('undefined') > 0 ? 'localhost': cookiePath;

/**
 * ######################################
 * Event-Handler for "Document ready"
 * ######################################
 */
$(function () {

    consentState = Cookies.get('cookie_consent');
    consentGroups = Cookies.get('cookie_groups');

    if (navigator.cookieEnabled) {
        /** Cookies are allowed in this browser */

        var iframes = $('iframe');
        $.each(iframes, function (index) {
            if(typeof($(this).attr('data-src')) != 'undefined'){

                var light = $(this).parent().hasClass('light');

                if (['youtube', 'vimeo', 'dailymotion'].some(term => $(this).attr('data-src').includes(term))) {
                    if (Cookies.get('remember_videos') != 'true') {
                        generateOverlay($(this), 'video', index, light);
                    } else {
                        $(this).attr('src', $(this).attr('data-src'));
                    }
                } else if (['maps', 'map', 'osm', 'openstreet'].some(term => $(this).attr('data-src').includes(term))) {
                    if (Cookies.get('remember_maps') != 'true') {
                        generateOverlay($(this), 'map', index, light);
                    } else {
                        $(this).attr('src', $(this).attr('data-src'));
                    }
                } else {
                    if (Cookies.get('remember_misc') != 'true') {
                        generateOverlay($(this), 'misc', index, light);
                    } else {
                        $(this).attr('src', $(this).attr('data-src'));
                    }
                }
            }
  
        });

        if (typeof consentState == 'undefined' || consentState == 'revoked') {
            /** Consent state not defined (no cookie) OR revoked */

                // Delete Google _ga-Cookies
            var allCookies = Cookies.get();
            for (var key in allCookies) {
                if (key.includes('_ga') || key.includes('_fbp')) {
                    Cookies.remove(key, {path: '/', domain: cookiePath});
                }
            }

            if (consentState == 'revoked' && $('body').hasClass('PrivacyPage')) {
                /** Open cookie consent on the privacy page only if it is revoked */

                if (!crawlerAgentRegex.test(navigator.userAgent)) {
                    new Modal(cookieApprovalModal, {
                        'backdrop': 'static',
                        'keyboard': false
                    }).show();
                }
            } else if (!$('body').hasClass('PrivacyPage')) {
                /** otherwise open it on every other page than privacy */

                if (!crawlerAgentRegex.test(navigator.userAgent)) {
                    new Modal(cookieApprovalModal, {
                        'backdrop': 'static',
                        'keyboard': false
                    }).show();
                }

            }

        } else if (consentState == 'set') {
            /** Consent state is defined (cookie exists) and it is defined as set */

            if (typeof consentGroups != 'undefined') {
                /** Consent groups are defined (cookie exists) */

                if (consentGroups.indexOf('comfort-cookies') > 0) {
                    Cookies.set('remember_videos', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
                    Cookies.set('remember_maps', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
                    Cookies.set('remember_misc', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
                }

                if (consentGroups.indexOf('tracking-cookies') > 0 || consentGroups.indexOf('targeting-cookies') > 0) {
                    /** Consent groups include "Tracking Cookies" or "Targeting-Cookies" */

                    if (typeof googleTagManagerID != 'undefined') {
                        /** TagManager-ID is set in the backend */

                        (function (w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({
                                'gtm.start':
                                    new Date().getTime(), event: 'gtm.js'
                            });
                            var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                            j.async = true;
                            j.src =
                                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                            f.parentNode.insertBefore(j, f);
                        })(window, document, 'script', 'dataLayer', "" + googleTagManagerID + "");

                        var noScriptTagGoogle = document.createElement('noscript');
                        var iframeGooglePixel = document.createElement('iframe');
                        iframeGooglePixel.src = "https://www.googletagmanager.com/ns.html?id=" + googleTagManagerID + "";
                        iframeGooglePixel.alt = "Google Tag Manager";
                        iframeGooglePixel.height = "0";
                        iframeGooglePixel.width = "0";
                        iframeGooglePixel.style = "display:none;visibility:hidden";
                        noScriptTagGoogle.appendChild(iframeGooglePixel);
                        document.getElementsByTagName('body')[0].appendChild(noScriptTagGoogle);
                    }
                }

                if (consentGroups.indexOf('targeting-cookies') > 1) {
                    Cookies.set('enable_marketing', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
                }

                

            }
        }
    } else {
        /** Cookies are disabled in this browser */

        $('#cookie-consent-revoke').remove();
        if (!crawlerAgentRegex.test(navigator.userAgent)) {
            new Modal(noCookiesModal, {
                'backdrop': 'static',
                'keyboard': false
            }).show();
        }
    }

});

/**
 * ######################################
 * Event-Handler for "Allow all"
 * ######################################
 */
$('#cookie-consent-allow').on('click', function (event) {
    event.preventDefault();
    Cookies.set('cookie_consent', 'set', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.set('cookie_groups', 'essential-cookies.comfort-cookies.tracking-cookies.targeting-cookies', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    Modal.getInstance(cookieApprovalModal).hide();
    setTimeout(function () {
        location.reload();
    }, 1000);
});

/**
 * ######################################
 * Event-Handler for "Apply selection"
 * ######################################
 */
$('#cookie-consent-groups').on('click', function (event) {
    event.preventDefault();
    Cookies.set('cookie_consent', 'set', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    var cookieGroupsWrapper = $(this).closest('.collapse');
    var switches = cookieGroupsWrapper.find('.custom-switch input');
    var cookieGroups = 'essential-cookies';

    if (switches.length > 0) {
        $.each(switches, function () {
            if ($(this).is(':checked')) {
                cookieGroups = cookieGroups + '.' + $(this).attr('id');
            }
        });
    }

    Cookies.set('cookie_groups', cookieGroups, {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    Modal.getInstance(cookieApprovalModal).hide();
    setTimeout(function () {
        location.reload();
    }, 500);
});

/**
 * ######################################
 * Event-Handler for "Revoke button"
 * ######################################
 */
$('#cookie-consent-revoke').on('click', function (event) {
    event.preventDefault();
    Cookies.set('cookie_consent', 'revoked', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.set('cookie_groups', 'essential-cookies', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    Cookies.remove('enable_marketing', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.remove('remember_maps', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.remove('remember_videos', {expires: 730, secure: true, sameSite: 'strict', path: '/'});
    Cookies.remove('remember_misc', {expires: 730, secure: true, sameSite: 'strict', path: '/'});

    location.reload();

});

/**
 * ######################################
 * Event-Handler for "Unlock iFrame"
 * ######################################
 */
$(document).on('click', '.unlock-iframe', (event) => {
    var overlay = $(event.target.closest('.embed-overlay'));
    var checkbox = overlay.find('.remember-embed-decision');
    var iframe = overlay.siblings('iframe');

    if (checkbox.is(':checked') && consentGroups.indexOf('comfort-cookies') > 0) {
        if (overlay.hasClass('embed-video')) {
            Cookies.set('remember_videos', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
        } else if (overlay.hasClass('embed-map')) {
            Cookies.set('remember_maps', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
        } else if (overlay.hasClass('embed-misc')) {
            Cookies.set('remember_misc', true, {expires: 730, secure: true, sameSite: 'strict', path: '/'});
        }
        location.reload();
    }

    iframe.attr('src', iframe.attr('data-src'));
    overlay.remove();
});

/**
 * ######################################
 * Create and append overlay for an iframe
 * by content type (diff. bg-image and button)
 * ######################################
 */
var generateOverlay = (iframe, type = 'misc', index = 0, light = false) => {
    var typeWord = "";

    switch (type) {
        case 'video':
            typeWord = "Video";
            break;
        case 'map':
            typeWord = "Karte";
            break;
        case 'misc':
            typeWord = "Inhalt";
            break;
    }

    var embedOverlay = '';

    if (!light){
        embedOverlay =
        '<div class="position-left position-top position-absolute bg-primary d-flex w-100 h-100 align-items-center justify-content-center embed-overlay embed-' + type + '">' +
        '<div class="text-white text-center text-shadow">' +
        '<p class="h5">Weitere unterhaltsame Inhalte für Sie!</p>' +
        '<p>Jetzt freischalten und multimedial genießen</p>';
    }else{
        embedOverlay =
        '<div class="position-left position-top position-absolute bg-primary d-flex w-100 h-100 align-items-center justify-content-center embed-overlay embed-' + type + '">' +
        '<div class="text-white text-center text-shadow">';
    }

    if (typeof consentGroups != 'undefined') {
        if (consentGroups.indexOf('comfort-cookies') > 0) {
            embedOverlay +=
                '<div class="custom-control custom-switch mb-2">' +
                '<input type="checkbox" class="remember-embed-decision custom-control-input" id="targeting-cookies-'+index+'">' +
                '<label class="custom-control-label" for="targeting-cookies-'+index+'">Entscheidung merken!</label>' +
                '</div>';
        }
    }

    embedOverlay +=
        '<p class="mb-0"><button class="unlock-iframe btn btn-outline-light btn-sm"><i class="fas fa-lock-open"></i>&nbsp;' + typeWord + ' freischalten</button></p>' +
        '</div>' +
        '</div>';

    var iframeWrapper = iframe.parent();
    iframeWrapper.addClass('position-relative');
    iframeWrapper.append(embedOverlay);
}


